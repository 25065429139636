import type { UpsertUserGroupAssociationRequestDto } from '@peter-park/group-association-management'
import type { SupportedCountry } from '@peter-park/js-plate-validation'
import {
  parsePlate,
  supportedCountriesList,
} from '@peter-park/js-plate-validation'
import { z } from 'zod'

const countriesEnum = z.enum(supportedCountriesList)
interface StrictPlate {
  plate: string
  country: SupportedCountry
}

function refinePlate(data: StrictPlate, context: z.RefinementCtx) {
  const validation = parsePlate(data.plate, data.country, false, true, true)
  switch (validation.error) {
    case 'invalid_plate': {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'zod.invalid_license_plate',
        path: ['plate'],
      })
      break
    }
    case 'missing-separator': {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'zod.missing_separator',
        path: ['plate'],
      })
      break
    }
    case 'invalid_district': {
      if (data.country === 'CH') {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'zod.invalid_canton_code',
          path: ['plate'],
        })
        return
      }
      if (data.country === 'DE') {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'zod.invalid_municipal',
          path: ['plate'],
        })
        return
      }
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'zod.invalid_province_code',
        path: ['plate'],
      })
      break
    }
  }
}

const plateArrayValidation = z.array(z.object({
  plate: z.string(),
  country: countriesEnum,
}).superRefine(refinePlate)).superRefine((data, context) => {
  // check if there are duplicate plates
  // we need to retrieve the first duplicated plate and store it's index
  // we can use a Set to check for duplicates
  if (data.length > 1) {
    let duplicatePlateIndex = -1
    const platesSet = new Set<string>()
    let index = 0
    while (index < data.length && duplicatePlateIndex === -1) {
      if (platesSet.has(data[index].plate))
        duplicatePlateIndex = index

      platesSet.add(data[index].plate)
      index++
    }

    if (duplicatePlateIndex > -1) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'zod.duplicated_plate',
        path: [duplicatePlateIndex.toString(), 'plate'],
      })
    }
  }
})

export const addUserGroupAssociationSchema = z.object({
  reason: z.string().optional(),
  plates: plateArrayValidation,
})

const simplePlateSchema = z.object({
  plate: z.string(),
  country: z.string().optional().nullable(),
})

export const editLicensePlateForGroupAssociationSchema = z.object({
  reason: z.string(),
  existingPlates: z.array(simplePlateSchema),
  plateToDelete: simplePlateSchema.optional(),
  previousPlate: z.string().optional(),
  previousCountry: z.string().optional().nullable(),
  country: countriesEnum,
  plate: z.string(),
}).superRefine(refinePlate).superRefine((data, context) => {
  if (data.existingPlates.some(plate => plate.plate === data.plate)) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'zod.duplicated_plate',
      path: ['plate'],
    })
  }
})

function transformAddUserGroupAssociationSchema(schema: typeof addUserGroupAssociationSchema) {
  return schema.transform((data): UpsertUserGroupAssociationRequestDto => {
    const formattedPlates = data.plates.map(({ plate, country }) => ({
      newPlate: { plate, country },
    }))
    return {
      items: formattedPlates,
      reason: data.reason ? data.reason : '',
    }
  })
}

function transformEditLicensePlateForGroupAssociationSchema(schema: typeof editLicensePlateForGroupAssociationSchema) {
  return schema.transform((data): UpsertUserGroupAssociationRequestDto => {
    const newPlate = { plate: data.plate, country: data.country }
    if (data.previousPlate) {
      return {
        items: [
          {
            newPlate: { plate: data.plate, country: data.country },
            oldPlate: { plate: data.previousPlate, country: data.previousCountry },
          },
        ],
        reason: data.reason,
      }
    }
    return {
      items: [
        {
          newPlate,
        },
      ],
      reason: data.reason,
    }
  })
}

export const transformedAddUserGroupAssociationSchema = transformAddUserGroupAssociationSchema(addUserGroupAssociationSchema)
export type AddUserGroupAssociationSchema = z.infer<typeof addUserGroupAssociationSchema>

export const transformedEditLicensePlateForGroupAssociationSchema = transformEditLicensePlateForGroupAssociationSchema(editLicensePlateForGroupAssociationSchema)
export type EditLicensePlateForGroupAssociationSchema = z.infer<typeof editLicensePlateForGroupAssociationSchema>
