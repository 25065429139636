import type { RemovableRef } from '@vueuse/core'
import { useI18nLocales } from '@peter-park/vue-common-utils'
import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'

export const userSettingsStore = defineStore('userSettings', (): {
  availableLocales: string[]
  navigatorLanguage: Ref<string | undefined>
  userSelectedLanguage: RemovableRef<string>
  latestShopVisited: RemovableRef<string>
  setLanguage: (locale: string) => void
  setLatestShopVisited: (shop: string) => void
} => {
  const { availableLocales, navigatorLanguage, userSelectedLanguage, setLanguage } = useI18nLocales('de-DE')

  const latestShopVisited = useStorage('latestShopVisited', '')
  function setLatestShopVisited(shop: string) {
    latestShopVisited.value = shop
  }

  return {
    availableLocales,
    userSelectedLanguage,
    setLanguage,
    navigatorLanguage,
    latestShopVisited,
    setLatestShopVisited,
  }
})
