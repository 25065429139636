import type { ResponseError } from '@/types/openapiRuntime'
import type { ShopInfoDto, UserShopGroupDto } from '@peter-park/shop'
import type { MaybeRef } from 'vue'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { useOpenApiClient } from './useOpenApiClient'

export function useGetUserShopGroups(shopId: MaybeRef<string>, isEnabled = true) {
  const { apiInstance } = useOpenApiClient()
  const queryClient = useQueryClient()

  const {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
    data,
    refetch,
    suspense,
  } = useQuery({
    queryKey: ['getUserShopGroups', shopId],
    queryFn: () => apiInstance.value.shopGroupControllerGetUserShopGroups({ shopId: unref(shopId) }),
    initialData: () => {
      const shopData = queryClient.getQueryData<Array<ShopInfoDto | undefined>, string[]>(['getShopByPath'])?.find(shop => shop?.id === unref(shopId))
      if (shopData)
        return shopData.userShopGroups
    },
    enabled: isEnabled,
    networkMode: 'always',
  })

  const httpStatus = computed(() => {
    return (error.value as ResponseError)?.response?.status
  })

  // groups WITHOUT Active plates -> user can see the setup card
  const availableUserShopGroups = computed(() => {
    // check if the group is visible in shop and has no plates or group has only expiring plates
    return data.value?.userShopGroups?.filter((group: UserShopGroupDto) => group.visible && (group.activePlates.length === 0 || group.activePlates.every(plate => (plate.validTo)))) ?? []
  })

  const expiredUserShopGroups = computed(() => {
    return data.value?.userShopGroups?.filter((group: UserShopGroupDto) => group.activePlates.length === 0 && group.expiredPlates.length > 0) ?? []
  })

  const activeUserShopGroups = computed(() => {
    return data.value?.userShopGroups?.filter((group: UserShopGroupDto) => group.activePlates.length > 0) ?? []
  })
  return {
    getUserShopGroupsIsLoading: isLoading,
    getUserShopGroupsIsFetching: isFetching,
    getUserShopGroupsIsError: isError,
    getUserShopGroupsIsSuccess: isSuccess,
    getUserShopGroupsError: error,
    getUserShopGroupsRefetch: refetch,
    getUserShopGroupsHttpErrorStatus: httpStatus,
    suspenseGetUserShopGroups: suspense,
    userShopGroups: data,
    expiredUserShopGroups,
    activeUserShopGroups,
    availableUserShopGroups,
  }
}
