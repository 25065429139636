import type { SupportedCountry } from '@peter-park/js-plate-validation'
import type {
  AddLicensePlateRequestBodyDto,
  UpdateLicensePlateRequestBodyDto,

} from '@peter-park/payment-hub'
import type { Plate } from '@peter-park/shop'
import {
  parsePlate,
  supportedCountriesList,

} from '@peter-park/js-plate-validation'

import { z } from 'zod'

const countriesEnum = z.enum(supportedCountriesList)

function validatePlate(data: { plate: string, country: SupportedCountry, existingLicensePlates: Plate[] }, context: z.RefinementCtx) {
  if (data.plate.length === 0) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'zod.field_required',
      fatal: true,
      path: ['plate'],
    })
  }
  const { error } = parsePlate(data.plate, data.country, false, true, true)
  switch (error) {
    case 'invalid_plate': {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'zod.invalid_license_plate',
        path: ['plate'],
      })
      break
    }
    case 'missing-separator': {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'zod.missing_separator',
        path: ['plate'],
      })
      break
    }
    case 'invalid_district': {
      if (data.country === 'CH') {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'zod.invalid_canton_code',
          path: ['plate'],
        })
        return
      }
      if (data.country === 'DE') {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'zod.invalid_municipal',
          path: ['plate'],
        })
        return
      }
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'zod.invalid_province_code',
        path: ['plate'],
      })
      break
    }
  }
  if (data.existingLicensePlates.some(plate => plate.plate === data.plate)) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'zod.duplicated_plate',
      path: ['plate'],
    })
  }
}

const baseSchema = z.object({
  reason: z.string(),
  existingLicensePlates: z.custom<Plate[]>(),
  country: countriesEnum,
  plate: z.string(),
})

export const addLicensePlateSchema = baseSchema.superRefine(validatePlate)

export const updateLicensePlateSchema = baseSchema.extend({
  previousPlate: z.string(),
  previousPlateCountry: countriesEnum.optional(),
}).superRefine(validatePlate)

export type ChangeLicensePlateSchema = z.infer<
  typeof updateLicensePlateSchema
>

export type AddLicensePlateSchema = z.infer<
  typeof addLicensePlateSchema
>

function transformUpdateLicensePlateSchema(
  schema: typeof updateLicensePlateSchema,
) {
  return schema.transform((data): UpdateLicensePlateRequestBodyDto => {
    return {
      newPlate: {
        plate: data.plate,
        country: data.country,
      },
      reason: data.reason,
      previousPlate: {
        plate: data.previousPlate,
        country: data.previousPlateCountry,
      },
    }
  })
}

function transformAddLicensePlateSchema(
  schema: typeof addLicensePlateSchema,
) {
  return schema.transform((data): AddLicensePlateRequestBodyDto => {
    return {
      plate: {
        plate: data.plate,
        country: data.country,
      },
      reason: data.reason,
    }
  })
}

export const transformedUpdateLicensePlateSchema = transformUpdateLicensePlateSchema(
  updateLicensePlateSchema,
)

export const transformedAddLicensePlateSchema = transformAddLicensePlateSchema(
  addLicensePlateSchema,
)
