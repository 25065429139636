import { ofetchAPICall } from '@/utils/apiClient'
import { useQuery } from '@tanstack/vue-query'

async function getAppVersionInfo() {
  return await ofetchAPICall<{
    appVersion: string
    buildTimestamp: Date
    commitHash: string
    commitDate: string
  }>({
    url: '/version.json',
    method: 'GET',
  })
}

export function useAppVersion() {
  const {
    isLoading,
    isFetching,
    data,
    error,
    isError,
    isPending,
    isSuccess,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ['appVersion'],
    queryFn: () => getAppVersionInfo(),
    enabled: true,
    networkMode: 'always',
    // refetch every 15 seconds
    refetchInterval: 60 * 1000,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  })

  watch(data, (newData) => {
    if (newData) {
      const commitHash = $APP_COMMIT_HASH
      if (commitHash !== newData.commitHash) {
        console.warn('App commit hash mismatch: ', commitHash, newData.commitHash)
        console.warn('Reloading the app...')
        window.location.reload()
        console.warn('App reloaded, new commit hash: ', commitHash)
      }
    }
  }, { immediate: true })

  return {
    appVersion: data,
    appVersionIsLoading: isLoading,
    appVersionIsFetching: isFetching,
    appVersionError: error,
    appVersionIsError: isError,
    appVersionIsPending: isPending,
    appVersionIsSuccess: isSuccess,
    appVersionIsRefetching: isRefetching,
    appVersionRefetch: refetch,
  }
}
