<script lang="ts" setup>
defineOptions({
  name: 'MultiAreaPill',
})

const { areas, boxed = false, viewLimit = 1, showIcon = true, small = false, isMobile = false }
  = defineProps<{
    /**
     * The areas where the ticket is valid
     * @example ['Area 1', 'Area 2']
     */
    areas: string[]
    /**
     * set to true to show the pill in a box
     */
    boxed?: boolean

    /**
     * set the limit of areas to show
     */
    viewLimit?: number

    /**
     * show icon or not
     */
    showIcon?: boolean

    /**
     * small text size
     */
    small?: boolean
    /**
     * set to true for mobile cards
     */
    isMobile?: boolean
  }>()

const textSize = computed(() => small ? 'text-xs' : 'text-sm')

const tooltipAreas = computed(() => areas.slice(viewLimit))
</script>

<template>
  <div class="position-relative flex items-center justify-between gap-2" :class="boxed ? 'w-full' : ''">
    <div class="flex flex-col gap-1">
      <div v-if="!boxed" class="position-relative flex items-center gap-2">
        <div v-if="showIcon" class="flex rounded">
          <span class="i-lucide:map-pin" :class="[boxed ? 'text-white' : 'text-primary-500', textSize]" />
        </div>
        <span :class="textSize">{{ areas[0] }}</span>
      </div>
      <div v-else class="position-relative flex gap-1">
        <div v-if="showIcon" class="flex rounded">
          <span class="i-lucide:map-pin text-xl text-primary-500" />
        </div>
        <div class="flex flex-wrap gap-1">
          <span v-for="area in areas.slice(0, viewLimit)" :key="area" class="my-auto h-fit rounded-2 bg-gray-60 px-2 text-3.5 leading-5">{{ area }}</span>
          <MultiAreaPillTooltip v-if="tooltipAreas.length > 0" :areas="tooltipAreas" pill-border-color="gray" :is-mobile="isMobile" />
        </div>
      </div>
    </div>
    <MultiAreaPillTooltip v-if="tooltipAreas.length > 0 && !boxed" :areas="tooltipAreas" :is-mobile="isMobile" />
  </div>
</template>
