import type { FetchError } from 'ofetch'
import { ofetch } from 'ofetch'

const client = ofetch.create({
  baseURL: '/',
})

export async function ofetchAPICall<T>({
  url,
  baseURL,
  headers,
  method,
  params,
  data,
  signal,
}: {
  url: string
  baseURL?: string
  headers?: {
    'Content-Type': string
  }
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  params?: any
  data?: unknown
  responseType?: string
  signal?: AbortSignal
}): Promise<T> {
  const response = await client<T>(
    url,
    {
      baseURL,
      method,
      headers,
      signal,
      ...(params ? { query: new URLSearchParams(params) } : {}),
      ...(data ? { body: JSON.stringify(data) } : {}),
    },
  )

  return response
}

export default ofetchAPICall
export type ErrorType<Error> = FetchError<Error>
