<script setup lang="ts">
defineOptions({
  name: 'NoTicketsSection',
})

const { t } = useI18n()
const router = useRouter()
const isAccountVerificationEnabled = inject<Ref<boolean>>('isAccountVerificationEnabled')

function handleToVerification() {
  router.push({ name: 'account', query: { section: 'verify' } })
}
</script>

<template>
  <div
    class="flex flex-col items-center justify-center gap-9 rounded-full bg-[radial-gradient(circle,white_60%,transparent_80%)] md:pa-6"
  >
    <img src="@/assets/emptyTickets.svg" alt="No Tickets">
    <div class="flex flex-col items-center justify-center gap-6">
      <div class="items-center gap-3 text-center text-sm text-gray-600 font-semibold leading-6 md:text-base">
        <div>{{ t('home.no_tickets_available') }}</div>
        <div v-if="isAccountVerificationEnabled">
          {{ t('home.no_tickets_available_verification_missing_subline') }}
        </div>
        <div v-else>
          {{ t('home.no_tickets_available_shop_has_no_tickets_subline') }}
        </div>
      </div>
      <UiButton
        v-if="isAccountVerificationEnabled"
        type="button"
        class="gradient-button max-w-[10.375rem] transform transition-shadow transition-transform duration-300 hover:scale-105 hover:shadow-lg"
        @click="handleToVerification"
      >
        <span class="text-sm font-600 leading-6 md:text-base">
          {{ t('home.to_verification') }}
        </span>
      </UiButton>
    </div>
  </div>
</template>

<style scoped>
.gradient-button {
  background: var(--gradient-gray-60050090-deg, linear-gradient(90deg, #5F46F8 0%, #A294F8 100%));
}
</style>
