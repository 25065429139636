<script lang="ts" setup>
import { TooltipArrow, TooltipContent, TooltipPortal, TooltipProvider, TooltipRoot, TooltipTrigger } from 'radix-vue'

defineOptions({
  name: 'MultiAreaPillTooltip',
})

const { areas, pillBorderColor = 'white', isMobile = false }
  = defineProps<{
    /**
     * The areas what tooltip needs to show
     * @example ['Area 1', 'Area 2']
     */
    areas: string[]
    /**
     * set the border-color of pill
     */
    pillBorderColor?: Colors
    /**
     * set to true for mobile views
     */
    isMobile?: boolean
  }>()

type Colors = 'gray' | 'white'

const pillBordercolorClasses = new Map<Colors, string>([
  ['white', 'b-white'],
  ['gray', 'b-gray-50'],
])

const isOpen = ref(false)

function toggleTooltip(value: boolean, skipForMobileView: boolean) {
  if (skipForMobileView && isMobile)
    return
  isOpen.value = value
}
</script>

<template>
  <TooltipProvider :delay-duration="150">
    <TooltipRoot :open="isOpen">
      <OnClickOutside @trigger="isOpen = false">
        <TooltipTrigger
          class="position-relative mr-4 bg-transparent"
          @click="toggleTooltip(!isOpen, false)"
          @mouseenter="toggleTooltip(true, true)"
          @mouseleave="toggleTooltip(false, true)"
        >
          <span class="position-absolute inline-block h-full w-full border rounded-full bg-primary-300" :class="pillBordercolorClasses.get(pillBorderColor)" />
          <span class="position-absolute left-1.5 inline-block h-full w-full border rounded-full bg-primary-400" :class="pillBordercolorClasses.get(pillBorderColor)" />
          <span class="position-relative left-3 inline-flex items-center gap-2 whitespace-nowrap border rounded-full bg-primary-500 px-2 py-1.5 text-3.5 text-white font-medium leading-2.5" :class="pillBordercolorClasses.get(pillBorderColor)">
            +{{ areas.length }}
          </span>
        </TooltipTrigger>
      </OnClickOutside>
      <TooltipPortal>
        <TooltipContent
          class="SlideDownAndFade will-change-[transform,opacity] select-none border border-primary-400 rounded-lg bg-white p-1 leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]"
          :side-offset="5"
        >
          <div class="max-h-40 flex flex-col gap-2 overflow-y-auto p-2">
            <div v-for="area in areas" :key="area" class="flex items-center gap-2">
              <div class="i-lucide:map-pin text-xl text-primary-500" />
              <span>{{ area }}</span>
            </div>
          </div>
          <TooltipArrow
            class="fill-primary-400"
            :width="8"
          />
        </TooltipContent>
      </TooltipPortal>
    </TooltipRoot>
  </TooltipProvider>
</template>
