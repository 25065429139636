<script lang="ts" setup>
import type { AvailablePurchaseOptionFixedPriceTicketDto } from '@peter-park/available-purchase-option'
import type { UserShopGroupDto } from '@peter-park/shop'
import { useTicketsAreas } from '@/composables/useTicketsAreas'

const { tickets, userShopGroups } = defineProps<{
  /**
   * list of tickets to display
   */
  tickets: AvailablePurchaseOptionFixedPriceTicketDto[]
  /**
   * list of user shop groups
   */
  userShopGroups: UserShopGroupDto[]
}>()

const emits = defineEmits<{
  /**
   * emitted when a ticket is selected
   * @param selected - the id of the selected ticket
   */
  selected: [string]
  selectUserGroup: [string]
}>()

const { getTicketAreaNames } = useTicketsAreas(tickets)
</script>

<template>
  <template v-if="tickets.length > 0 || userShopGroups.length > 0">
    <div v-if="userShopGroups.length" class="mb-10 w-full flex flex flex-col gap-5">
      <ParkingPermissionsCard
        v-for="userShopGroup in userShopGroups"
        :key="userShopGroup.id"
        :translations="userShopGroup.translations"
        :areas="userShopGroup.areas"
        @select-group="emits('selectUserGroup', userShopGroup.id)"
      />
    </div>
    <div class="grid grid-cols-1 mx-auto w-full justify-items-center gap-4 lg:grid-cols-2 lg:gap-8">
      <TicketCard v-for="ticket in tickets" :key="ticket.id" :price="ticket.price" :areas="getTicketAreaNames(ticket)" :currency-code="ticket.currencyCode" :translations="ticket.translations" @selected="emits('selected', ticket.id)">
        <template #icon>
          <img src="@/assets/calendar-year.svg" class="h-8 w-max" alt="calendar-year">
        </template>
      </TicketCard>
    </div>
  </template>
  <NoTicketsSection v-else />
</template>
