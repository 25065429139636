<script setup lang="ts">
import { useShopAuth } from '@/composables'
import { userSettingsStore } from '@/stores'
import { storeToRefs } from 'pinia'

const router = useRouter()

const userSettings = userSettingsStore()
const { userSelectedLanguage, latestShopVisited } = storeToRefs(userSettings)
const { t } = useI18n()

const { handleLogin, handleLogout, isAuthenticated } = useShopAuth(latestShopVisited)

const languages = computed(() => {
  return [
    {
      label: t('english'),
      value: 'en',
      icon: 'i-circle-flags:gb',
    },
    {
      label: t('german'),
      value: 'de',
      icon: 'i-circle-flags:de',
    },
    {
      label: t('french'),
      value: 'fr',
      icon: 'i-circle-flags:fr',
    },
    {
      label: t('italian'),
      value: 'it',
      icon: 'i-circle-flags:it',
    },
  ]
})

function setLanguage(language: string) {
  userSettings.setLanguage(language)
}

function gotoAccount() {
  if (isAuthenticated.value)
    router.push({ name: 'account', query: { section: 'active-tickets' } })
  else
    handleLogin()
}
</script>

<template>
  <div
    class="h-screen w-screen flex flex-col justify-between overflow-x-hidden bg-gray-50 text-gray-900 font-sans transition duration-100 dark:bg-gray-900 dark:text-gray-50"
  >
    <img
      src="@/assets/backgroundImage.avif"
      class="fixed h-screen w-screen object-cover"
    >
    <AppHeader
      :selected-language="userSelectedLanguage"
      :languages="languages"
      :is-authenticated="isAuthenticated"
      @update:selected-language="setLanguage"
      @login="handleLogin"
      @logout="handleLogout"
      @goto-account="gotoAccount"
    />
    <div class="z-1 mx-auto flex flex-1 items-center px-3 pb-3 pb-5 pt-6 sm:px-5 sm:pt-10">
      <slot />
    </div>
  </div>
</template>
