export const supportedLocations = ['de', 'it', 'ch', 'at']

export type SupportedLocations = typeof supportedLocations[number]

export const location = window.location.host.split('.').pop() || 'de'

export const privacyLinks = new Map<SupportedLocations, string>([
  ['de', 'https://www.mh-parkservice.com/datenschutz'],
  ['it', 'https://www.mh-parkservice.com/it/protezione-dei-dati'],
  ['ch', 'https://www.mh-parkservice.com/ch/datenschutz'],
  ['at', 'https://www.mh-parkservice.com/at/datenschutz'],
])

export const agbLinks = new Map<SupportedLocations, string>([
  ['de', 'https://www.mh-parkservice.com/agb-online'],
  ['it', 'https://www.mh-parkservice.com/it/agb-online'],
  ['ch', 'https://www.mh-parkservice.com/ch/agb-online'],
  ['at', 'https://www.mh-parkservice.com/at/agb-online'],
])

export const impressumLinks = new Map<SupportedLocations, string>([
  ['de', 'https://www.mh-parkservice.com/impressum'],
  ['it', 'https://www.mh-parkservice.com/it/impronta'],
  ['ch', 'https://www.mh-parkservice.com/ch/impressum'],
  ['at', 'https://www.mh-parkservice.com/at/impressum'],
])

export const copyrightText = new Map<SupportedLocations, string>([
  ['de', '2025 Mobility Hub Parkservice GmbH.'],
  ['it', '2025 Mobility Hub Parkservice Italia S.r.l.'],
  ['ch', '2025 Mobility Hub Parkservice Switzerland GmbH.'],
  ['at', '2025 Mobility Hub Parkservice Austria GmbH.'],
])

export function getCopyRightText(shopCountry?: SupportedLocations) {
  const location = shopCountry?.toLowerCase()

  if (location && supportedLocations.includes(location))
    return copyrightText.get(location)

  // Fallback to German
  return copyrightText.get('de')
}

export function getLink(linkArray: Map<SupportedLocations, string>, shopCountry?: SupportedLocations) {
  const location = shopCountry?.toLowerCase()

  if (location && supportedLocations.includes(location))
    return linkArray.get(location as SupportedLocations)

  // Fallback to German
  return linkArray.get('de')
}
